import React from "react"
import styled from "styled-components"
import { colors } from "../components/globalStyle"

import Layout from "../components/layout"
import SEO from "../components/seo"

const StyledLayout = styled.div`
  padding: 5rem 7rem;
  padding-bottom: 2rem;
  .services-title {
    text-transform: uppercase;
    font-size: 1.5rem;
    color: ${colors.blueGrey};
    font-family: Baloo;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
    margin: 0;
  }
  p {
    font-family: Montserrat;
    font-size: 0.85rem;
    line-height: 1.6rem;
    margin: 0;
  }

  @media (max-width: 1024px) {
    padding: 3rem 5rem !important;
    padding-bottom: 2rem !important;
  }
  @media (max-width: 910px) {
    padding: 3rem 3rem !important;
    padding-bottom: 1rem !important;
  }
  @media (max-width: 768px) {
    padding: 3rem 2rem !important;
  }
  @media (max-width: 430px) {
    padding: 2rem 2rem !important;
  }
`

const SecondPage = () => (
  <Layout>
    <StyledLayout>
      <SEO title="TERMS AND CONDITIONS | NUNKKI" />
      <h2 className="services-title">STANDARD TERMS AND CONDITIONS</h2>
      <p>
        These are the standard terms and conditions for any NUNKKI supplied
        product or service (collectively: Mobile-Apps, business software,
        hardware solutions, IoT, Custom UI/UX Design, Website development,
        consulting and support services), and applies to all agreements and all
        work undertaken by NUNKKI for its Clients.
      </p>
      <h2 className="services-title">OUR FEES AND DEPOSITS</h2>
      <p>
        To initiate the project, an upfront deposit (25-50%) of the total fee is
        to be paid upon the agreement of the proposal. The remaining fee shall
        become due when the project is complete in full and meets the Clients
        expectations. The scope of work, requirements, specifications and the
        project deliverables, including the “approved work” and “rejected work”
        clauses, are listed in the NUNKKI Project Agreement. NUNKKI reserves
        the right not to commence any project work, until the upfront deposit
        has been paid in full. An upfront deposit is only refundable to the
        Client, if NUNKKI does not deliver the project list of deliverables
        listed in the NUNKKI Project Agreement. The deposit is not refundable
        if the project implementation work or development work has started. The
        deposit is not refundable if the Client terminates the project at no
        fault of NUNKKI.
      </p>
      <h2 className="services-title">
        SUPPLY OF REQUIREMENTS AND SPECIFICATIONS
      </h2>

      <p>
        The Client must supply all requirements and specifications to NUNKKI in
        a timely manner to complete the project work in accordance with
        NUNKKI’s Project Agreement timelines. Such requirements and
        specifications may include, but is not limited to, design, hardware and
        digital requirements and specifications.
      </p>
      <br></br>
      <p>
        A Clients delays in supplying requirements and specifications, after the
        project implementation has commenced will delay the project completion
        date. NUNKKI reserves the right to renegotiate or extend previously
        agreed timelines, or completion dates by a reasonable amount of time.
      </p>
      <br></br>
      <p>
        If the Client does not supply requirements and specifications within the
        NUNKKI Project Agreement timelines, NUNKKI reserves the right to
        invoice the Client for any part or parts of the project deliverables
        that have already been completed.
      </p>
      <h2 className="services-title">CHANGE ORDER(S)</h2>

      <p>
        The Client throughout the project initiation and implementation has the
        right to request project changes. The Client must provide a detailed
        change order(s), this may be provided in the form of an email, or a
        certified letter. The Client may be liable for extra charges and fees
        associated to project change order(s). NUNKKI reserves the right to
        limit the number of project change order(s). In the event of conflict
        between the terms of the project agreement and a Change Order(s), the
        terms of the NUNKKI Project Agreement shall govern.
      </p>
      <br></br>
      <p>
        For Custom UI/UX Design and website development NUNKKI permits
        flexibility and allows certain change variations to the original
        requirements, specification and project deliverables. However, any major
        deviation will be charged as an addition, at the rate of $XX.XX per
        hour.
      </p>
      <h2 className="services-title">
        PROJECT MANAGER OR SINGLE POINT OF CONTACT (SPOC)
      </h2>
      <p>
        The Client and NUNKKI shall both assign a Project Manager’s or SPOC’s
        to manage the implementation of the project. The Project Managers or
        SPOC shall be responsible for: (i) managing the day-to-day activities
        under the NUNKKI Project Agreement, (ii) serving as liaisons between
        the parties, (iii) assigning and scheduling the appropriate personnel to
        perform all of the required services under the NUNKKI Project
        Agreement, and (iv) authorising and executing any and all Change
        Order(s).
      </p>
      <br></br>
      <p>
        The Client shall acknowledge and agree that the Client Project Manager
        or SPOC shall have the proper authority and power to execute, perform
        and oversee the duties and responsibilities set forth in the NUNKKI
        Project Agreement. NUNKKI acknowledges and agrees that the Project
        Manager or SPOC shall have the proper authority and power to execute,
        perform and oversee the duties and responsibilities outlined in the
        NUNKKI Project Agreement.
      </p>
      <h2 className="services-title">
        PROJECT DELAYS AND CLIENT RESPONSIBILITIES
      </h2>
      <p>
        Any timelines or estimates that NUNKKI provide are contingent upon the
        Client’s full co-operation. The Client agrees to perform all tasks
        assigned to the Client as set forth in the NUNKKI Project Agreement or
        a Change Order(s) Agreement and is to provide all assistance and
        cooperation to NUNKKI in order to complete the project in a timely and
        efficient manner.
      </p>
      <br></br>
      <p>
        NUNKKI shall not be deemed in breach of the agreement in the event of
        NUNKKI’s failure to meet its responsibilities or timelines caused by
        the Client's failure to meet (or delay in) its responsibilities and
        timelines, set forth in the NUNKKI project Agreement or Change Order(s)
        Agreement.
      </p>
      <br></br>
      <p>
        In the event of any such failure or delay by the Client, NUNKKI
        reserves the right to renegotiate or extend any previously agreed
        timelines, or completion dates by a reasonable amount of time. The
        Client shall continue to make timely payments to NUNKKI as set forth in
        the NUNKKI Project Agreement and or Change Order(s) Agreement, as if
        all timelines or schedules had been completed by NUNKKI.
      </p>
      <br></br>
      <p>
        The Client shall be responsible for administrating, at its own expense,
        any changes or additions to the Client's current systems, software, and
        hardware that may be required to support the operation of the Project.
        Unless otherwise contracted with NUNKKI or reflected in a Change
        Order(s), the Client shall be responsible for initially administrating
        and then maintaining any databases on the project as well as providing
        all content for the project.
      </p>
      <h2 className="services-title">PROJECT DELIVERABLES: APPROVED WORK</h2>
      <p>
        During the status review phases of the project lifecycle the Client’s
        Project Manager or SPOC will be called upon to review the deliverables.
        The Client’s Project Manager or SPOC must notify NUNKKI in writing or
        via email of any unsatisfactory deliverables within 7 days of the status
        review period. Any of the work which has not been reported in writing or
        via email to NUNKKI as unsatisfactory, within 7 day of the status
        review period will be deemed to have been approved. Once approved, or
        deemed approved, work cannot subsequently be rejected by the Client’s
        Project Manager or SPOC. During the final project completion review
        phase, a final approval by the Client’s Project Manager or SPOC, written
        or via email, will deem the project as complete and the remaining
        balance of the project fee will be due.
      </p>
      <h2 className="services-title">PROJECT DELIVERABLES: REJECTED WORK</h2>
      <p>
        If the Client’s Project Manager or SPOC rejects any of the project
        deliverables within 7 day of the status review period, thenNUNKKI will
        be responsible for proactively work to remedy any points deemed
        unsatisfactory. If the Client’s Project Manager or SPOC rejects any
        subsequent work performed by NUNKKI to remedy any points recorded as
        being unsatisfactory, NUNKKI, acting reasonably, reserves the right to
        cancel the NUNKKI Project Agreement and take measures to recover
        payment for the completed work.
      </p>
      <h2 className="services-title">SUPPORT AND MAINTENANCE</h2>
      <p>
        Any support and maintenance services, updates, versions, or new releases
        shall be contracted under a separate agreement between NUNKKI and the
        Client. Maintenance and support rights or obligations for any
        third-party products or equipment that are used in the Project and are
        available through NUNKKI’s respective vendor(s)/manufacture(s) can be
        assigned to the project by NUNKKI. NUNKKI shall not use any
        intellectual property or any third-party products or equipment in the
        project without the Client's written consent.
      </p>
      <h2 className="services-title">E-COMMERCE</h2>
      <p>
        The Client is responsible for complying with all relevant laws relating
        to e-commerce, and to the full extent permitted by law will hold
        harmless, protect, and defend and indemnify NUNKKI and its
        subcontractors from any claim, penalty, tax, tariff loss or damage
        arising from the Client’s use of Internet electronic commerce.
      </p>
      <h2 className="services-title">MARKETING</h2>
      <p>
        The Client will grant NUNKKI the right to use the name and service
        marks of Client in its marketing materials or other oral, electronic, or
        written promotions, which shall include naming Client as a client of
        NUNKKI, including a brief scope of services provided. Any use of
        NUNKKI’s logos or links on Client's product or marketing must be
        approved in writing or via email by NUNKKI. Either party may elect to
        issue a press release related to this Agreement. In doing so, any
        release shall be approved by the other party and such approval shall not
        be unreasonably withheld.
      </p>
      <h2 className="services-title">SUBCONTRACTING</h2>
      <p>
        Unless stipulated by the Client in the NUNKKI Project Agreement.
        NUNKKI reserves the right to subcontract any services that NUNKKI
        agreed to perform for the Client.
      </p>
      <h2 className="services-title">NON-DISCLOSURE</h2>
      <p>
        NUNKKI (and subcontractors) agree that no Client confidential
        information will be disclosed to third parties.
      </p>
      <h2 className="services-title">CONFIDENTIALITY</h2>
      <p>
        The parties agree to hold each other's Proprietary or Confidential
        Information in strict confidence. The parties agree not to make each
        other's Proprietary or Confidential Information available in any form to
        any third party or to use each other's Proprietary or Confidential
        Information for any purpose other than as specified in this NUNKKI
        Project Agreement. Each party's Proprietary or Confidential Information
        shall remain the sole and exclusive property of that party. The parties
        agree that in the event of use or disclosure by the other party other
        than as specifically provided for in this NUNKKI Project Agreement, the
        non-disclosing party may be entitled to equitable relief. NUNKKI and
        Client acknowledge and agree that their obligations of confidentiality
        with respect to Proprietary or Confidential Information shall continue
        in effect for a total period of three (3) years succeeding the project
        completion.
      </p>
      <h2 className="services-title">
        USE AND OWNERSHIP OF INTELLECTUAL PROPERTY
      </h2>
      <p>
        The Client must obtain all necessary permissions in respects to the use
        of all copy, digital imaging, registered company logos, names and
        trademarks, or any other material which is supplied to NUNKKI as part
        of the Client project. The Client must insure and will not hold NUNKKI
        accountable for any claims or legal actions against the Client, related
        to the use of unauthorized intellectual property used by the Client
        (collectively used in: Mobile-Apps, business software, hardware
        solutions, IoT, Custom UI/UX Design, Website development, consultation,
        support services or other).
      </p>
      <h2 className="services-title">WEBSITE LICENSING</h2>
      <p>
        Once the Client has paid NUNKKI in full for the project work, NUNKKI
        will grant the Client the licenses to use the website and its related
        software and contents for the life of the website/mobile applications.
      </p>
      <h2 className="services-title">DATA BACKUPS</h2>
      <p>
        NUNKKI is not responsible for content and data residing on the Client’s
        website. It is the Client’s soul responsibility to maintain appropriate
        backups for their website contents and data. NUNKKI, in no event shall
        be held liable for any loss of any content or data. NUNKKI cannot be
        held liable for restoring any Client content or data. Notwithstanding
        the foregoing, on some occasions and in certain circumstances, with
        absolutely no obligation, NUNKKI may be able to restore some or all of
        the Client’s data that has been deleted, as of a certain date and time.
        However, NUNKKI cannot guarantee that the Client’s content or data will
        be available.
      </p>
      <h2 className="services-title">
        OWNERSHIP OF DOMAIN NAMES AND WEB HOSTING
      </h2>
      <p>
        NUNKKI reserves the right to withhold account credentials for domain
        name registrations and/or web hosting details that where purchased by
        NUNKKI on behalf of the Client until the total project balance has been
        payed out to NUNKKI by the Client. This includes the reimbursement of
        additional expenses incurred by NUNKKI not stipulated in the NUNKKI
        Project Agreement or Change Order(s) Agreement.
      </p>
      <h2 className="services-title">CROSS BROWSER COMPATIBILITY</h2>
      <p>
        NUNKKI endeavors to ensure that the web sites created by NUNKKI are
        compatible with all current modern web browsers including most recent
        versions of Explorer, Firefox, Google Chrome and Safari. Third party
        extensions, where used, may not have the same level of support for all
        browsers. Where appropriate NUNKKI will substitute alternative
        extensions or implement other solutions, on a best effort basis, where
        any incompatibilities are found.
      </p>
      <h2 className="services-title">
        ACCURACY OF NUNKKI’S WEBSITE INFORMATION
      </h2>
      <p>
        Occasionally there may be information on the NUNKKI Website with
        typographical errors, inaccuracies or omissions that may relate to
        product descriptions, pricing, availability, promotions and offers.
        NUNKKI reserve the right to correct any errors, inaccuracies or
        omissions, and to change or update information or cancel orders if any
        information on the Website or on any related Service is inaccurate at
        any time without prior notice (including after the Client has submitted
        their order). NUNKKI undertakes no obligation to update, amend or
        clarify information on the Website including, without limitation,
        pricing information, except as required by law. No specified updates or
        refresh dates applied on the Website should be taken to indicate that
        all information on the Website or on any related Service have been
        modified or updated. ASSIGNMENT The Client may not assign, resell,
        sub-license or otherwise transfer or delegate any of the Client’s rights
        or obligations hereunder, in whole or in part, without NUNKKI’s prior
        written or email consent, which consent shall be at NUNKKI’s own sole
        discretion and without obligation; any such assignment or transfer shall
        be null and void. NUNKKI is free to assign any of its rights or
        obligations hereunder, in whole or in part, to any third-party as part
        of the sale of all or substantially all its assets or stock or as part
        of a merger. CHANGES AND AMENDMENTS TO NUNKKI’S WEBSITE NUNKKI reserve
        the right to modify NUNKKI’s Terms and Conditions or its policies
        relating to the Website or Products or Services at any time, effective
        upon posting of an updated version of this Agreement on the NUNKKI
        Website. When changes are made NUNKKI will revise the updated date at
        the bottom of this page. Continued use of the NUNKKI Website after any
        such changes shall constitute the Clients consent to such changes.
        ADDITIONAL EXPENSES The Client will reimburse NUNKKI for any additional
        expenses which are not stipulated in the NUNKKI Project Agreement or
        Change Order(s) Agreements, including but not limited to the purchase of
        templates, third party software, hardware, tools, stock photographs,
        fonts, domain name registration, web hosting or comparable expenses.
      </p>
      <h2 className="services-title">PAYMENT</h2>
      <p>
        Upon the project completion the balance payment is immediately due and
        liable to be paid by Client in full.
      </p>
      <h2 className="services-title">LIMITATIONS OF LIABILITY</h2>
      <p>
        Under no circumstances shall NUNKKI, its contracted providers,
        officers, agents, or anyone else involved in creating, producing, or
        distributing of the Client's project products be liable for any direct,
        indirect, incidental, special or consequential damages that result from
        the use of or inability to use the project Product; or that results from
        mistakes, omissions, interruptions, deletion of files, errors, defects,
        delays in operation, or transmission or any failure of performance,
        whether or not limited to acts of God, communication failure, theft,
        destruction or unauthorized access to Client's records, programs or
        services. The Client acknowledges that this paragraph shall apply to all
        NUNKKI project products, software, programs and services. THE TOTAL
        LIABILITY FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN
        CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE) TO THE OTHER SHALL
        NOT EXCEED THE DEVELOPMENT PRICE (AS DEFINED IN THE NUNKKI PROJECT
        AGREEMENT). THIS LIMITATION OF LIABILITY SHALL APPLY EVEN IF THE EXPRESS
        WARRANTIES SET FORTH ABOVE FAIL IN THEIR ESSENTIAL PURPOSE.
      </p>
      <h2 className="services-title">DISCLAIMER</h2>
      <p>
        IN NO EVENT, UNLESS REQUIRED BY APPLICABLE LAW OR AGREED TO IN WRITING,
        SHALL NUNKKI, OR ANY PERSON BE LIABLE FOR ANY LOSS, EXPENSE OR DAMAGE,
        OF ANY TYPE OR NATURE ARISING OUT OF THE USE OF, OR INABILITY TO USE
        NUNKKI PRODUCT, SOFTWARE OR PROGRAM, OR INCLUDING, BUT NOT LIMITED TO,
        CLAIMS, SUITS OR CAUSES OF ACTION INVOLVING ALLEGED INFRINGEMENT OF
        COPYRIGHTS, PATENTS, TRADEMARKS, TRADE SECRETS, OR UNFAIR COMPETITION.
      </p>
      <h2 className="services-title">Contact us</h2>
      <p>
        If you have any questions about our Terms and Conditions please contact
        us. This document was last updated on September 3, 2020
      </p>
    </StyledLayout>
  </Layout>
)

export default SecondPage
